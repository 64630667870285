#finicityConnectIframe {
    display: flex;
    z-index: 1200;
    background-color: var(--background, #f9fafc);
    border-radius: 5px;
    height: 100vh;
    overflow: auto;
    padding: 20px;
    margin: auto;
	z-index: 1600;
    width: 30%;
}
@media screen and (max-width: 600px) {
    #finicityConnectIframe {
        width: 90%;
    }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
    #finicityConnectIframe {
        width: 60%;
    }
}
#finicity-iframe-container{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    padding: 19px 0;
}